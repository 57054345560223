<template>
  <a
    class="event__wrapper"
    :href="
      isExternalEvent
        ? localePath(`/${provider}/${event.id}`)
        : localePath(
            `/${event.tenantName}/event/${event.id}/${event.name.replace(/[^a-zA-Z0-9-_]/g, '-').replace(/-+/gi, '-')}`
          )
    "
  >
    <div class="event">
      <div class="event__content">
        <div class="event__poster">
          <img
            v-if="event.mainImageUri"
            :src="event.mainImageUri"
            alt="Banner"
            loading="lazy"
          />
          <img v-else :src="placeholderImage" alt="Banner" loading="lazy" />
        </div>
        <div class="event__content-title">
          <h2>{{ event.name }}</h2>
        </div>
        <div v-if="event.startAt" class="event__content-date">
          <IconCalendar color="lighten2" />
          <p>
            {{ getStartedAt(event) }}
          </p>
        </div>
        <div class="event__content-location">
          <IconLocation color="lighten2" />
          <p>
            {{ event.venue.address.street1 }}, {{ event.venue.address.zip }}
            {{ event.venue.address.city }}, {{ event.venue.address.country }}
          </p>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import moment from 'moment-timezone';
import IconCalendar from '@/components/general/icons/IconCalendar';
import IconLocation from '@/components/general/icons/IconLocation';
import { EVENT_TYPES } from '~/utils/utils';
export default {
  name: 'Event',
  components: { IconCalendar, IconLocation },
  props: {
    event: {
      type: Object,
      default() {
        return {};
      }
    },
    provider: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      eventTypes: EVENT_TYPES
    };
  },
  computed: {
    backgroundImage() {
      return {
        'background-image': `url(${this.event.mainImageUri})`
      };
    },
    isExternalEvent() {
      return this.event.type === this.eventTypes.EXTERNAL;
    },
    placeholderImage() {
      return this.isExternalEvent
        ? require('assets/img/featured_event.webp')
        : require('assets/img/event_placeholder.webp');
    }
  },
  methods: {
    getStartedAt(event) {
      return `${moment
        .parseZone(event.startAt)
        .format(
          'ddd, DD MMM YYYY, HH:mm'
        )} - ${moment.parseZone(event.endAt).format('HH:mm')}`;
    }
  }
};
</script>
